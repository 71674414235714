/* eslint-disable sort-keys */
const allCountries = [
  { id: 4, name: 'Afghanistan', alpha2: 'af' },
  { id: 8, name: 'Albania', alpha2: 'al' },
  { id: 12, name: 'Algeria', alpha2: 'dz' },
  { id: 20, name: 'Andorra', alpha2: 'ad' },
  { id: 24, name: 'Angola', alpha2: 'ao' },
  { id: 28, name: 'Antigua and Barbuda', alpha2: 'ag' },
  { id: 32, name: 'Argentina', alpha2: 'ar' },
  { id: 51, name: 'Armenia', alpha2: 'am' },
  { id: 36, name: 'Australia', alpha2: 'au' },
  { id: 40, name: 'Austria', alpha2: 'at' },
  { id: 31, name: 'Azerbaijan', alpha2: 'az' },
  { id: 44, name: 'Bahamas', alpha2: 'bs' },
  { id: 48, name: 'Bahrain', alpha2: 'bh' },
  { id: 50, name: 'Bangladesh', alpha2: 'bd' },
  { id: 52, name: 'Barbados', alpha2: 'bb' },
  { id: 112, name: 'Belarus', alpha2: 'by' },
  { id: 56, name: 'Belgium', alpha2: 'be' },
  { id: 84, name: 'Belize', alpha2: 'bz' },
  { id: 204, name: 'Benin', alpha2: 'bj' },
  { id: 64, name: 'Bhutan', alpha2: 'bt' },
  { id: 68, name: 'Bolivia', alpha2: 'bo' },
  { id: 70, name: 'Bosnia and Herzegovina', alpha2: 'ba' },
  { id: 72, name: 'Botswana', alpha2: 'bw' },
  { id: 76, name: 'Brazil', alpha2: 'br' },
  { id: 96, name: 'Brunei Darussalam', alpha2: 'bn' },
  { id: 100, name: 'Bulgaria', alpha2: 'bg' },
  { id: 854, name: 'Burkina Faso', alpha2: 'bf' },
  { id: 108, name: 'Burundi', alpha2: 'bi' },
  { id: 132, name: 'Cabo Verde', alpha2: 'cv' },
  { id: 116, name: 'Cambodia', alpha2: 'kh' },
  { id: 120, name: 'Cameroon', alpha2: 'cm' },
  { id: 124, name: 'Canada', alpha2: 'ca' },
  { id: 140, name: 'Central African Republic', alpha2: 'cf' },
  { id: 148, name: 'Chad', alpha2: 'td' },
  { id: 152, name: 'Chile', alpha2: 'cl' },
  { id: 156, name: 'China', alpha2: 'cn' },
  { id: 170, name: 'Colombia', alpha2: 'co' },
  { id: 174, name: 'Comoros', alpha2: 'km' },
  { id: 178, name: 'Congo', alpha2: 'cg' },
  { id: 180, name: 'Congo, Democratic Republic of the', alpha2: 'cd' },
  { id: 188, name: 'Costa Rica', alpha2: 'cr' },
  { id: 384, name: 'Côte d\'Ivoire', alpha2: 'ci' },
  { id: 191, name: 'Croatia', alpha2: 'hr' },
  { id: 192, name: 'Cuba', alpha2: 'cu' },
  { id: 196, name: 'Cyprus', alpha2: 'cy' },
  { id: 203, name: 'Czechia', alpha2: 'cz' },
  { id: 208, name: 'Denmark', alpha2: 'dk' },
  { id: 262, name: 'Djibouti', alpha2: 'dj' },
  { id: 212, name: 'Dominica', alpha2: 'dm' },
  { id: 214, name: 'Dominican Republic', alpha2: 'do' },
  { id: 218, name: 'Ecuador', alpha2: 'ec' },
  { id: 818, name: 'Egypt', alpha2: 'eg' },
  { id: 222, name: 'El Salvador', alpha2: 'sv' },
  { id: 226, name: 'Equatorial Guinea', alpha2: 'gq' },
  { id: 232, name: 'Eritrea', alpha2: 'er' },
  { id: 233, name: 'Estonia', alpha2: 'ee' },
  { id: 748, name: 'Eswatini', alpha2: 'sz' },
  { id: 231, name: 'Ethiopia', alpha2: 'et' },
  { id: 242, name: 'Fiji', alpha2: 'fj' },
  { id: 246, name: 'Finland', alpha2: 'fi' },
  { id: 250, name: 'France', alpha2: 'fr' },
  { id: 266, name: 'Gabon', alpha2: 'ga' },
  { id: 270, name: 'Gambia', alpha2: 'gm' },
  { id: 268, name: 'Georgia', alpha2: 'ge' },
  { id: 276, name: 'Germany', alpha2: 'de' },
  { id: 288, name: 'Ghana', alpha2: 'gh' },
  { id: 300, name: 'Greece', alpha2: 'gr' },
  { id: 308, name: 'Grenada', alpha2: 'gd' },
  { id: 320, name: 'Guatemala', alpha2: 'gt' },
  { id: 324, name: 'Guinea', alpha2: 'gn' },
  { id: 624, name: 'Guinea-Bissau', alpha2: 'gw' },
  { id: 328, name: 'Guyana', alpha2: 'gy' },
  { id: 332, name: 'Haiti', alpha2: 'ht' },
  { id: 340, name: 'Honduras', alpha2: 'hn' },
  { id: 348, name: 'Hungary', alpha2: 'hu' },
  { id: 352, name: 'Iceland', alpha2: 'is' },
  { id: 356, name: 'India', alpha2: 'in' },
  { id: 360, name: 'Indonesia', alpha2: 'id' },
  { id: 364, name: 'Iran (Islamic Republic of)', alpha2: 'ir' },
  { id: 368, name: 'Iraq', alpha2: 'iq' },
  { id: 372, name: 'Ireland', alpha2: 'ie' },
  { id: 376, name: 'Israel', alpha2: 'il' },
  { id: 380, name: 'Italy', alpha2: 'it' },
  { id: 388, name: 'Jamaica', alpha2: 'jm' },
  { id: 392, name: 'Japan', alpha2: 'jp' },
  { id: 400, name: 'Jordan', alpha2: 'jo' },
  { id: 398, name: 'Kazakhstan', alpha2: 'kz' },
  { id: 404, name: 'Kenya', alpha2: 'ke' },
  { id: 296, name: 'Kiribati', alpha2: 'ki' },
  { id: 408, name: 'Korea (Democratic People\'s Republic of)', alpha2: 'kp' },
  { id: 410, name: 'Korea, Republic of', alpha2: 'kr' },
  { id: 414, name: 'Kuwait', alpha2: 'kw' },
  { id: 417, name: 'Kyrgyzstan', alpha2: 'kg' },
  { id: 418, name: 'Lao People\'s Democratic Republic', alpha2: 'la' },
  { id: 428, name: 'Latvia', alpha2: 'lv' },
  { id: 422, name: 'Lebanon', alpha2: 'lb' },
  { id: 426, name: 'Lesotho', alpha2: 'ls' },
  { id: 430, name: 'Liberia', alpha2: 'lr' },
  { id: 434, name: 'Libya', alpha2: 'ly' },
  { id: 438, name: 'Liechtenstein', alpha2: 'li' },
  { id: 440, name: 'Lithuania', alpha2: 'lt' },
  { id: 442, name: 'Luxembourg', alpha2: 'lu' },
  { id: 450, name: 'Madagascar', alpha2: 'mg' },
  { id: 454, name: 'Malawi', alpha2: 'mw' },
  { id: 458, name: 'Malaysia', alpha2: 'my' },
  { id: 462, name: 'Maldives', alpha2: 'mv' },
  { id: 466, name: 'Mali', alpha2: 'ml' },
  { id: 470, name: 'Malta', alpha2: 'mt' },
  { id: 584, name: 'Marshall Islands', alpha2: 'mh' },
  { id: 478, name: 'Mauritania', alpha2: 'mr' },
  { id: 480, name: 'Mauritius', alpha2: 'mu' },
  { id: 484, name: 'Mexico', alpha2: 'mx' },
  { id: 583, name: 'Micronesia (Federated States of)', alpha2: 'fm' },
  { id: 498, name: 'Moldova, Republic of', alpha2: 'md' },
  { id: 492, name: 'Monaco', alpha2: 'mc' },
  { id: 496, name: 'Mongolia', alpha2: 'mn' },
  { id: 499, name: 'Montenegro', alpha2: 'me' },
  { id: 504, name: 'Morocco', alpha2: 'ma' },
  { id: 508, name: 'Mozambique', alpha2: 'mz' },
  { id: 104, name: 'Myanmar', alpha2: 'mm' },
  { id: 516, name: 'Namibia', alpha2: 'na' },
  { id: 520, name: 'Nauru', alpha2: 'nr' },
  { id: 524, name: 'Nepal', alpha2: 'np' },
  { id: 528, name: 'Netherlands', alpha2: 'nl' },
  { id: 554, name: 'New Zealand', alpha2: 'nz' },
  { id: 558, name: 'Nicaragua', alpha2: 'ni' },
  { id: 562, name: 'Niger', alpha2: 'ne' },
  { id: 566, name: 'Nigeria', alpha2: 'ng' },
  { id: 807, name: 'North Macedonia', alpha2: 'mk' },
  { id: 578, name: 'Norway', alpha2: 'no' },
  { id: 512, name: 'Oman', alpha2: 'om' },
  { id: 586, name: 'Pakistan', alpha2: 'pk' },
  { id: 585, name: 'Palau', alpha2: 'pw' },
  { id: 591, name: 'Panama', alpha2: 'pa' },
  { id: 598, name: 'Papua New Guinea', alpha2: 'pg' },
  { id: 600, name: 'Paraguay', alpha2: 'py' },
  { id: 604, name: 'Peru', alpha2: 'pe' },
  { id: 608, name: 'Philippines', alpha2: 'ph' },
  { id: 616, name: 'Poland', alpha2: 'pl' },
  { id: 620, name: 'Portugal', alpha2: 'pt' },
  { id: 634, name: 'Qatar', alpha2: 'qa' },
  { id: 642, name: 'Romania', alpha2: 'ro' },
  { id: 643, name: 'Russian Federation', alpha2: 'ru' },
  { id: 646, name: 'Rwanda', alpha2: 'rw' },
  { id: 659, name: 'Saint Kitts and Nevis', alpha2: 'kn' },
  { id: 662, name: 'Saint Lucia', alpha2: 'lc' },
  { id: 670, name: 'Saint Vincent and the Grenadines', alpha2: 'vc' },
  { id: 882, name: 'Samoa', alpha2: 'ws' },
  { id: 674, name: 'San Marino', alpha2: 'sm' },
  { id: 678, name: 'Sao Tome and Principe', alpha2: 'st' },
  { id: 682, name: 'Saudi Arabia', alpha2: 'sa' },
  { id: 686, name: 'Senegal', alpha2: 'sn' },
  { id: 688, name: 'Serbia', alpha2: 'rs' },
  { id: 690, name: 'Seychelles', alpha2: 'sc' },
  { id: 694, name: 'Sierra Leone', alpha2: 'sl' },
  { id: 702, name: 'Singapore', alpha2: 'sg' },
  { id: 703, name: 'Slovakia', alpha2: 'sk' },
  { id: 705, name: 'Slovenia', alpha2: 'si' },
  { id: 90, name: 'Solomon Islands', alpha2: 'sb' },
  { id: 706, name: 'Somalia', alpha2: 'so' },
  { id: 710, name: 'South Africa', alpha2: 'za' },
  { id: 728, name: 'South Sudan', alpha2: 'ss' },
  { id: 724, name: 'Spain', alpha2: 'es' },
  { id: 144, name: 'Sri Lanka', alpha2: 'lk' },
  { id: 729, name: 'Sudan', alpha2: 'sd' },
  { id: 740, name: 'Suriname', alpha2: 'sr' },
  { id: 752, name: 'Sweden', alpha2: 'se' },
  { id: 756, name: 'Switzerland', alpha2: 'ch' },
  { id: 760, name: 'Syrian Arab Republic', alpha2: 'sy' },
  { id: 762, name: 'Tajikistan', alpha2: 'tj' },
  { id: 834, name: 'Tanzania, United Republic of', alpha2: 'tz' },
  { id: 764, name: 'Thailand', alpha2: 'th' },
  { id: 626, name: 'Timor-Leste', alpha2: 'tl' },
  { id: 768, name: 'Togo', alpha2: 'tg' },
  { id: 776, name: 'Tonga', alpha2: 'to' },
  { id: 780, name: 'Trinidad and Tobago', alpha2: 'tt' },
  { id: 788, name: 'Tunisia', alpha2: 'tn' },
  { id: 792, name: 'Turkey', alpha2: 'tr' },
  { id: 795, name: 'Turkmenistan', alpha2: 'tm' },
  { id: 798, name: 'Tuvalu', alpha2: 'tv' },
  { id: 800, name: 'Uganda', alpha2: 'ug' },
  { id: 804, name: 'Ukraine', alpha2: 'ua' },
  { id: 784, name: 'United Arab Emirates', alpha2: 'ae' },
  { id: 826, name: 'United Kingdom of Great Britain and Northern Ireland', alpha2: 'gb' },
  { id: 840, name: 'United States of America', alpha2: 'us' },
  { id: 858, name: 'Uruguay', alpha2: 'uy' },
  { id: 860, name: 'Uzbekistan', alpha2: 'uz' },
  { id: 548, name: 'Vanuatu', alpha2: 'vu' },
  { id: 862, name: 'Venezuela (Bolivarian Republic of)', alpha2: 've' },
  { id: 704, name: 'Viet Nam', alpha2: 'vn' },
  { id: 887, name: 'Yemen', alpha2: 'ye' },
  { id: 894, name: 'Zambia', alpha2: 'zm' },
  { id: 716, name: 'Zimbabwe', alpha2: 'zw' }]
export { allCountries }
